export {
  navigationLink,
  navigation,
  mobileNavigation
};

function navigation(page) {
  return {
    activePage: page,
  }
}

function navigationLink(hasChildren) {
  return {
    hasChildren: hasChildren | false,
    clicked: false,
    handleLink: function(event) {
      if (!screen.width >= 1024) {
        if (!this.clicked && (this.hasChildren || this.hasChildren === 1)) {
          event.preventDefault();
          this.clicked = true;
        }
      }
    }
  };
}

function mobileNavigation() {
  return {
    open: false,
    activeTree: "",
    activeSubTree: "",
    activateTree: function(val) {
      if (this.activeTree == val) {
        this.activeTree = "";
      } else {
        this.activeTree = val;
      }
    },
    activateSubTree: function(val) {
      if (this.activeSubTree == val) {
        this.activeSubTree = "";
      } else {
        this.activeSubTree = val;
      }
    }
  };
}