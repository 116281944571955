require('simplycountdown.js/dist/simplyCountdown.min')

export function svgHover(element) {
  return {
    svg: element,
    changeColor: function () {
      if (this.svg.style.fill === "") {
        this.svg.style.fill = "#B2001A";
      } else {
        this.svg.style = "";
      }
    }
  };
}

export function webinarFragen(faqs) {
  return {
    faqs: faqs,
    openTab: '',
    setTab: function (tab) {
      if (this.openTab === tab) {
        this.openTab = ""
      } else {
        this.openTab = tab
      }
    },
  }
}

export function mobileActions() {
  return {
    showMore: false,
    activeTab: '',
    chat: document.querySelector("#bf-expert-chat"),
    ansprechpartner: document.querySelector("#full-screen-ansprechpartner"),
    kontakt: document.querySelector("#kontakt"),
    toggleChat: function () {
      if (this.activeTab === 'chat') {
        this.showMore = false
        this.activeTab = '';
      } else {
        this.activeTab = 'chat';
      }

      this.chat.classList.toggle("translate-x-full");
      this.ansprechpartner.classList.add("translate-x-full");
      this.kontakt.classList.add("translate-x-full");
    },
    toggleAnsprechpartner: function () {
      if (this.activeTab === 'ansprechpartner') {
        this.showMore = false
        this.activeTab = '';
      } else {
        this.activeTab = 'ansprechpartner';
      }

      this.ansprechpartner.classList.toggle("translate-x-full");
      this.chat.classList.add("translate-x-full");
      this.kontakt.classList.add("translate-x-full");
    },
    toggleKontakt: function () {
      if (this.activeTab === 'kontakt') {
        this.showMore = false
        this.activeTab = '';
      } else {
        this.activeTab = 'kontakt';
      }

      this.kontakt.classList.toggle("translate-x-full");
      this.chat.classList.add("translate-x-full");
      this.ansprechpartner.classList.add("translate-x-full");
    },
    showMoreActions: function () {
      if (this.activeTab === 'ansprechpartner') {
        this.toggleAnsprechpartner();
        this.showMore = false
      } else if (this.activeTab === 'chat') {
        this.toggleChat();
        this.showMore = false
      } else if (this.activeTab === 'kontakt') {
        this.toggleKontakt();
        this.showMore = false
      } else if (this.activeTab === '') {
        this.showMore = !this.showMore;
      }
    }
  }
}

export function countdown(start) {
  return {
    start: start,
    startCountdown: function () {
      let start = new Date(this.start)

      simplyCountdown(this.$el, {
        year: start.getFullYear(),
        month: start.getUTCMonth()+1,
        day: start.getUTCDate(),
        hours: start.getUTCHours()+1,
        words: {
          days: {singular: 'Tag', plural: 'Tage'},
          hours: {singular: 'Stunde', plural: 'Stunden'},
          minutes: {singular: 'Minute', plural: 'Minuten'},
          seconds: {singular: 'Sekunde', plural: 'Sekunden'},
        },
        onEnd: function () {
          return `<span>Abgeschlossen</span>`;
        }
      })
    }
  }
}

export function shareBtn() {
  return {
    shareData: {
      title: document.title || '',
      url: window.location.href || '',
      text: document.description || '',
    },
    links: {
      facebook: '',
      instagram: '',
      twitter: '',
      linkedin: '',
    },
    setup: function () {
      self = this;

      this.links = {
        facebook: `http://www.facebook.com/share.php?u=${self.shareData.url}`,
        twitter: `https://twitter.com/share?url=${self.shareData.url}`,
        linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${self.shareData.url}&title=${self.shareData.title}`,
        xing: `https://www.xing.com/spi/shares/new?url=${self.shareData.url}`,
      }
    },
    share: async function () {
      if (navigator.share) {
        // Web Share API is supported
        const self = this

        navigator.share({
          title: self.shareData.title,
          text: self.shareData.text,
          url: self.shareData.url,
        }).then(() => {
          console.log('Thanks for sharing!');
        })
          .catch(console.error);
      } else {
        // Fallback
      }
    },
  }
}
