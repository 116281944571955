import Swiper from "swiper";

window.addEventListener("load", () => {
  new Swiper(".header-slider", {
    direction: "horizontal",
    effect: "fade",
    speed: 1000,
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: true
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: false,
    }
  });
});

/**
 * todo: move to bsn namespace
 */
window.mobileOnlySlider = function () {
  return {
    swiper: null,
    element: null,
    initSwiper: function (element) {
      this.element = element

      this.swiper = new Swiper(element, {
        direction: "horizontal",
        effect: "fade",
        speed: 1000,
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: true
        },
      })

      this.toggleSwiper()
      let self = this


      window.addEventListener('resize', function () {
        self.toggleSwiper()
      })
    },
    toggleSwiper: function () {
      if (window.innerWidth > 768) {
        // disabling swiper
        this.element.firstElementChild.classList.remove('swiper-wrapper')
        this.swiper.destroy()
      } else {
        // check for swiper-wrapper in order to prevent a loop
        if (!this.element.firstElementChild.classList.contains('swiper-wrapper')) {
          // enabling swiper
          this.element.firstElementChild.classList.add('swiper-wrapper')
          this.initSwiper(this.element)
        }
      }
    }
  }
}

export function slider(maxItems = 4) {
  return {
    element: null,
    maxItemsPerSlide: maxItems,
    swiper: null,
    init: function(element = '.slider-container') {
      if (typeof element === 'string') {
        this.element = document.querySelector(element)
      } else {
        this.element = element
      }

      let self = this

      this.swiper = new Swiper(this.element, {
        slidesPerView: 1,

        loop: true,

        pagination: {
          el: '.slider-pagination',
          clickable: true,
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: true
        },

        speed: 1000,
        spaceBetween: 30,

        breakpoints: {
          // when window width is >= 320px
          648: {
            slidesPerView: self.maxItemsPerSlide >= 2 ? 2 : self.maxItemsPerSlide,
          },
          // when window width is >= 480px
          1024: {
            slidesPerView: self.maxItemsPerSlide >= 3 ? 3 : self.maxItemsPerSlide,
          },
          // when window width is >= 640px
          1280: {
            slidesPerView: self.maxItemsPerSlide >= 4 ? 4 : self.maxItemsPerSlide,
          }
        },
      })
    },
  }
}
