import Cookies from "js-cookie";

export {ansprechpartner, niederlassung};

function ansprechpartner(ansprechpartner = {}, niederlassung = {}) {
    return {
        data: {
            search: Cookies.get("plz") || "",
            category: [],
            tasks: [],
            unternehmen: null,
            ansprechpartner: ansprechpartner || {},
            niederlassung: niederlassung || {}
        },
        searchPlz: async function () {
            let url =
                `${window.location.origin}/api/ansprechpartner?plz=${this.data.search}` +
                `&lang=${document.documentElement.lang}`;

            if (this.data.category.length > 0) {
                this.data.category.forEach(function (item) {
                    url += `&kategorien[]=${item}`;
                });
            }

            if (this.data.tasks.length > 0) {
                this.data.tasks.forEach(item => {
                    url += `&aufgaben[]=${item}`;
                });
            }

            if (this.data.unternehmen) {
                url += `&unternehmen=${this.data.unternehmen}`;
            }

            if (this.data.search !== "" && this.data.search !== undefined) {
                const response = await fetch(url);
                this.data = {...this.data, ...(await response.json())};

                this.dispatchEvent();

                Cookies.set("plz", this.data.search, {expires: 100});
            } else {
                Cookies.remove("plz");
            }
        },
        showPlzResult() {
            return (
                Object.entries(this.data.ansprechpartner).length > 0 &&
                Object.entries(this.data.niederlassung).length > 0
            );
        },
        update(data) {
            this.data = {...this.data, ...data};
        },
        reset() {
            this.data.ansprechpartner = {};
            this.data.niederlassung = {};

            this.dispatchEvent();

            Cookies.remove("plz");
        },
        dispatchEvent() {
            let event = new CustomEvent("has-set-plz", {
                detail: {
                    niederlassung: this.data.niederlassung,
                    ansprechpartner: this.data.ansprechpartner
                }
            });

            window.dispatchEvent(event);
        },
        refresh(shouldRefresh = false) {
            this.data.search = Cookies.get("plz");
            if (shouldRefresh) {
                this.searchPlz().then(() => console.info('refreshed Contactor'));
            }
        },
        analyticsEventForMail: function () {
            // todo: correct location for where the link got clicked
            ga("send", "event", {
                eventLabel: this.data.ansprechpartner.name,
                eventCategory: "Seitenbanner",
                eventAction: "E-Mail",
                eventValue: this.data.ansprechpartner.email
            });
        },
        analyticsEventForTel: function () {
            ga("send", "event", {
                eventLabel: this.data.ansprechpartner.name,
                eventCategory: "Seitenbanner",
                eventAction: "Telefonnummer",
                eventValue: this.data.ansprechpartner.tel
            });
        },
        linkAttributeMail: function () {
            let element = document.createElement("a");

            if (this.data.ansprechpartner.email) {
                element.text = this.data.ansprechpartner.email;
                element.href = `mailto:${this.data.ansprechpartner.email}`;

                return element.outerHTML;
            }

            element.text = "online@jansentore.com";
            element.href = `mailto:${"online@jansentore.com"}`;

            return element.outerHTML;
        },
        linkAttributeTel: function () {
            let element = document.createElement("a");

            if (this.data.ansprechpartner.tel) {
                element.text = this.data.ansprechpartner.tel;
                element.href = `tel:${this.data.ansprechpartner.tel}`;

                return element.outerHTML;
            }

            element.text = "+49 4965 89 88 0";
            element.href = `tel:${"+49 4965 89 88 0"}`;

            return element.outerHTML;
        }
    };
}

function niederlassung() {
    return {
        data: {
            ansprechpartner: {},
            niederlassung: {}
        },
        update(data) {
            this.data = {...this.data, ...data};
        },
        analyticsEventForMail: function () {
            ga("send", "event", {
                eventLabel: this.data.ansprechpartner.name,
                eventCategory: "Seitenbanner",
                eventAction: "E-Mail",
                eventValue: this.data.ansprechpartner.email
            });
        },
        analyticsEventForTel: function () {
            ga("send", "event", {
                eventLabel: this.data.ansprechpartner.name,
                eventCategory: "Seitenbanner",
                eventAction: "Telefonnummer",
                eventValue: this.data.ansprechpartner.tel
            });
        },
        linkAttributeMail: function () {
            let element = document.createElement("a");

            if (this.data.ansprechpartner.email) {
                element.text = this.data.ansprechpartner.email;
                element.href = `mailto:${this.data.ansprechpartner.email}`;

                return element.outerHTML;
            }

            element.text = "online@jansentore.com";
            element.href = `mailto:${"online@jansentore.com"}`;

            return element.outerHTML;
        },
        linkAttributeTel: function () {
            let element = document.createElement("a");

            if (this.data.ansprechpartner.tel) {
                element.text = this.data.ansprechpartner.tel;
                element.href = `tel:${this.data.ansprechpartner.tel}`;

                return element.outerHTML;
            }
            // todo: change to dynamic version or store initial data in external/global object
            element.text = "+49 4965 89 88 0";
            element.href = `tel:+49 4965 89 88 0`;

            return element.outerHTML;
        }
    };
}
