export default function() {
  return {
    element: document.querySelector("#bf-expert-chat"),
    showChat: function() {
      this.element.classList.remove("translate-x-full");
    },
    hideChat: function() {
      this.element.classList.add("translate-x-full");
    },
    toggleChat: function() {
      this.element.classList.toggle("translate-x-full");
    }
  };
}
