export default function () {
    return {
        data: {
            activeTab: null,
            activeDescription: null
        },


        changeDescription(description) {
            if (this.descriptionIsOpen(description)) {
                this.data.activeDescription = '';
            } else {
                this.data.activeDescription = description;
            }
        },

        descriptionIsOpen(description) {
            return this.data.activeDescription === description;
        },


        changeTab(tab) {
            this.data.activeTab = tab;
        },

        tabIsOpen(tab) {
            return this.data.activeTab === tab;
        }
    }
};
