import "alpinejs";
import produktdetails from "./produktdetails";
import profichat from "./profichat";
import {ansprechpartner, niederlassung} from "./ansprechpartner";
import {mobileNavigation, navigation, navigationLink} from "./navigation";
import * as helper from "./helper";
import {slider} from "./slider";
import {DropzoneHandler, Endpoints} from 'js-pimcore-formbuilder';
import {Chart, registerables} from "chart.js";


window.bsn = {
  ansprechpartner,
  niederlassung,
  produktdetails,
  profichat,
  navigationLink,
  navigation,
  mobileNavigation,
  ...helper,
  slider
};

const container = document.querySelectorAll('.wysiwyg table')

container.forEach((table) => {
  const wrapper = document.createElement('div')

  table.parentNode.insertBefore(wrapper, table)
  wrapper.appendChild(table)

  table.parentElement.classList.add('overflow-x-auto')
})

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.formbuilder.ajax-form').forEach((form) => {
    new DropzoneHandler(form);
  });
});
