import Swiper from 'swiper';

const swiperProdEl = document.getElementById('swiperCounterProducts');

if (swiperProdEl) {

  const slideCount = swiperProdEl.innerHTML;

  const swiper = new Swiper('.slider-container-products', {
    slidesPerView: 1,

    loop: true,

    pagination: {
      el: '.slider-pagination-products',
      clickable: true,
    },

    autoplay: {
      delay: 3500,
      disableOnInteraction: true
    },

    speed: 1000,
    spaceBetween: 30,

    breakpoints: {
      // when window width is >= 320px
      648: {
        slidesPerView: slideCount < 2 ? slideCount : 2,
      },
      // when window width is >= 480px
      1024: {
        slidesPerView: slideCount < 3 ? slideCount : 3,
      },
      // when window width is >= 640px
      1280: {
        slidesPerView: slideCount < 3 ? slideCount : 3,
      }
    },

  });

}
